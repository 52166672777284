html {
	width: 100%;
	height: 100%;
}
body {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	/* background-color: #0F243E; */
	background-color: #364056;
	color: #FFFFFF;
	/* font-size: 0.8rem; */
	font-size: 0.9rem;
}
a {
	color: #009EFF;
}
a:hover {
	color: #00CCFF;
}
input[type=checkbox] {
	cursor: pointer;
}
.txt {
	background-color: rgb(31, 73, 125);
	color: #FFFFFF;
	border: 1px solid #DEE2E6;
	padding-left: 0.25rem;
}

.title {
	/* font-size: 1.3rem; */
	font-size: 1.2rem;
}

.hide {
	display: none !important;
}
.clickable {
	cursor: pointer;
}
.color_clickable {
	cursor: pointer;
	color: #0000FF;
}
.color_clickable:hover {
	
}
.h-clickable {
	color: white;
	cursor: pointer;
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}
.h-clickable:hover {
	background-color: rgb(31, 73, 125);
}
.h-clickable-disable {
	color: rgb(101, 101, 101);
	background-color: rgb(191, 191, 191);
	cursor: default;
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}
.h-clickable-disable:hover {
}

.s-map canvas {
	width: 100% !important;
	height: 100% !important;
}

.modal-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	display: none;
	/*z-index: 100000;*/
}
.modal-window {
	/* 
	width: 80%;
	height: 400px;*/
	position: fixed;
	border: 2px solid #DEE2E6;
	background-color: #0F243E;
	display: none;
	/*z-index: 100001;*/
}
.modal-overlay-1 {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.0);
	display: none;
	/*z-index: 100000;*/
}

.main-container {
	width: 100%;
	height: 100%;
	min-width: 1000px;
	min-height: 700px;
}

.column {
	flex: 1;
}
.column + .column {
	/* margin-left: .5rem; */
	margin-left: 1px;
}
.column0 {
	flex: 1;
}

.grow {
	flex: 1;
}
.grow + .grow {
	/* margin-top: .5rem; */
	margin-top: 1px;
}
.grow0 {
	flex: 1;
}

/** For dosha(spIndex1kmDanger) */
.dosha3 {
	background-color: rgba( 12,   0,  12, 1.0);		/* Level 5 */
	color: rgba(255, 255, 255, 1.0);
}
.dosha2 {
	background-color: rgba(255,  40,   0, 1.0);		/* Level 3 */
	color: rgba(255, 255, 255, 1.0);
}
.dosha1 {
	background-color: rgba(242, 231,   0, 1.0);		/* Level 2 */
	color: rgba(  0,   0,   0, 1.0);
}
.dosha0 {
	background-color: rgba(196, 215, 155, 1.0);		/* Level NONE */
	color: rgba(  0,   0,   0, 1.0);
}
.dosha_disabled {
	background-color: rgba(160, 160, 160, 1.0);
}
/** For dosha(spIndex1kmDanger) */

/** For hRain(sfIndex1kmDanger) */
.hRain3 {
	background-color: rgba(170,   0, 170, 1.0);		/* Level 4 */
	color: rgba(255, 255, 255, 1.0);
}
.hRain2 {
	background-color: rgba(255,  40,   0, 1.0);		/* Level 3 */
	color: rgba(255, 255, 255, 1.0);
}
.hRain1 {
	background-color: rgba(242, 231,   0, 1.0);		/* Level 2 */
	color: rgba(  0,   0,   0, 1.0);
}
.hRain0 {
	background-color: rgba(196, 215, 155, 1.0);		/* Level NONE */
	color: rgba(  0,   0,   0, 1.0);
}
.hRain_disabled {
	background-color: rgba(160, 160, 160, 1.0);
}
/** For hRain(sfIndex1kmDanger) */

/** For flood(sfRyIndex1kmDanger) */
.flood4 {
	background-color: rgba( 12,   0,  12, 1.0);		/* Level 5 */
	color: rgba(255, 255, 255, 1.0);
}
.flood3 {
	background-color: rgba(170,   0, 170, 1.0);		/* Level 4 */
	color: rgba(255, 255, 255, 1.0);
}
.flood2 {
	background-color: rgba(255,  40,   0, 1.0);		/* Level 3 */
	color: rgba(255, 255, 255, 1.0);
}
.flood1 {
	background-color: rgba(242, 231,   0, 1.0);		/* Level 2 */
	color: rgba(  0,   0,   0, 1.0);
}
.flood0 {
	background-color: rgba(196, 215, 155, 1.0);		/* Level NONE */
	color: rgba(  0,   0,   0, 1.0);
}
.flood_disabled {
	background-color: rgba(160, 160, 160, 1.0);
}
/** For flood(sfRyIndex1kmDanger) */

/** For hRadnowc, quantities and ensembles */
.b3 {
	background-color: rgba(108,   0, 140, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
.b2 {
	background-color: rgba(255,   0,   0, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
.b1 {
	background-color: rgba(255, 255,   0, 1.0);
	color: rgba(  0,   0,   0, 1.0);
}
.b0 {
	background-color: rgba(196, 215, 155, 1.0);
	color: rgba(  0,   0,   0, 1.0);
}
.b_disabled {
	background-color: rgba(160, 160, 160, 1.0);
}
/** For quantities and ensembles */

/** For distributions */
.c5 {
	background-color: rgba(  0,   0,   0, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
.c4 {
	background-color: rgba(108,   0, 140, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
.c3 {
	background-color: rgba(200,  11, 255, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
.c2 {
	background-color: rgba(255,   0,   0, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
.c1 {
	background-color: rgba(255, 255,   0, 1.0);
	color: rgba(  0,   0,   0, 1.0);
}
.c0 {
	background-color: rgba(196, 215, 155, 1.0);
	color: rgba(  0,   0,   0, 1.0);
}
.c_disabled {
	background-color: rgba(160, 160, 160, 1.0);
}
/** For distributions */

/** For weatherWarn */
.bg00 {
}
.bg10 {
	background-color: rgba(255, 255,   0, 1.0);
	color: rgba(  0,   0,   0, 1.0);
}
.bg30 {
	background-color: rgba(255,   0,   0, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
.bg50 {
	background-color: rgba(108,   0, 140, 1.0);
	color: rgba(255, 255, 255, 1.0);
}
/** For weatherWarn */

/* 
.mapboxgl-ctrl-top-right {
	margin: -6px -6px 0px 0px !important;
} */
.tab {
	border-radius: 0.5rem 0.5rem 0 0 / 0.5rem 0.5rem 0 0;
}
.tab-selected {
	background-color: #0F243E;
	border-bottom-color: #0F243E !important;
}
.tab-others {
	background-color: rgb(31, 73, 125);
	border-bottom-color: #FFFFFF !important;
}

/* For settings */
.sts-cell {
	height: 90px;
}

/* For graphs */
.white {
	font-size: 0.8rem;
	font-weight: 100;
	font-style: inherit;
	font-family: inherit;
}
.white line {
	stroke: white;
}
.white path {
	stroke: white;
}
.grid line {
	stroke: white;
	stroke-width: 1.2px;
}
.grid path {
	storke-width: 0;
}

.dropdown {
	background-color: rgb(31, 73, 125);
	color: white;
}
.dropdown-menu {
	margin-left: -1px;
	padding: 0;
	font-size: 0.8rem;
	background-color: rgb(31, 73, 125);
	color: white;
	border-radius: 0;
	min-width: 0rem;
}
.dropdown-item {
	padding-left: 0.5rem;
	padding-right: 0rem;
	background-color: rgb(31, 73, 125);
	color: white;
}
.dropdown-item:hover {
	background-color: rgb(81, 123, 175);
	/*background-color: red;*/
	color: white;
}
.dropdown-toggle {
	padding-bottom: 0.15rem;
}
.dropdown-toggle::after {
	float: right;
	margin-top: 0.45rem;
	border-top: 0.45rem solid;
	border-left: 0.3rem solid transparent;
	border-right: 0.3rem solid transparent;
}
.btn-elements {
	padding: 0.07rem 0.5rem 0.2rem 0.5rem;
	font-size: 0.8rem;
	background-color: rgb(31, 73, 125);
	color: white;
	border-radius: 0;
}
.btn-elements:hover {
	background-color: rgb(31, 73, 125);
	color: white;
}

.cbx {
	margin-top: 0.25rem;
	margin-right: 0.25rem;
}
.slt {
	color: #FFFFFF;
	border-color: #DEE2E6;
	background-color: rgb(31, 73, 125);
}
.height0 {
	height: 0px;
}

.dropdown-submenu-s {
	position: relative !important;
}
.dropdown-submenu-s>.dropdown-menu-sub {
	display: none;
	top: 0;
	left: 100%;
	margin-top: -1px;
	margin-left: 0px;
}
.dropdown-submenu-s:hover>.dropdown-menu-sub {
	display: block;
}
.dropdown-item-sub {
	padding-bottom: 0.35rem;
	float: left;
}
.dropdown-toggle-right {
	white-space: nowrap;
}
.dropdown-toggle-right::after {
	display: inline-block;
	content: "";
	border-left: 0.45rem solid;
	border-top: 0.3rem solid transparent;
	border-bottom: 0.3rem solid transparent;
}

.news_split {
	border-top: 2px solid #FFFFFF;
}

.test {
	color: #FF0000;
	transition: .4s;
}



.mapboxgl-popup-content {
	padding: 8px 8px 6px 8px;
}
.legend-container {
}
.legend-container img {
	margin-top: 0.5rem;
}

.bars {
	/*fill: steelblue;*/
}
.axis_ww path {
	display: none;
}
.axis_y path {
	display: none;
}
.v {
	overflow: hidden;
}
.hover-line {
	stroke: white/*#6F257F*/;
	stroke-width: 1px;
}
.value-rect {
	fill: none;
	pointer-events: all;
}
.ttl {
	fill: white;
	font-size: 14px;
}
.ttp {
	position: absolute;
	text-align: center;
	top: 0px;
	left: 0px;
	width: auto;
	height: auto;
	padding: 5px;
	font: 12px;
	font-weight: bold;
	background-color: black;
	color: white;
	-webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
	visibility: hidden;
	z-index: 100000;
	border: 1px solid #FF0000;
}
.ttp_t {
	width: 320px; /*220px;*/
}
.ttp_l {
	width: 220px; /*130px;*/
	text-align: right;
	padding-right: 8px;
}
.ttp_r {
	text-align: left;
}
.lnk {
	fill: white; /* #1967D2 */;
	font-size: 15px;
}

.graph {
	/*color: #FFFFFF;*/
}
/* 
.axis--x path {
	display: none;
} */
.js_slider .ui-slider-handle {
	width: 10px;
	height: 16px;
	top: -7px;
	margin-left: -5px;
}

@keyframes blinkAnimeS1 {
	  0% {color: white;}
	 50% {color: black;}
}
.text_blink {
	animation: blinkAnimeS1 1s steps(1, end) infinite;
}

/* For Table typhoon header */
.tt_wrap {
	position: relative;
	height: 46px;
	overflow: hidden;
	font-size: 1.2rem;
}
.tt_cont {
	position: absolute;
	padding: 0px;
	height: 46px;
}
.tt_ani_t2l2 {width: 1120px; -webkit-animation: 12s moveT2L2 infinite linear;}
.tt_ani_t2l1 {width:  940px; -webkit-animation: 11s moveT2L1 infinite linear;}
.tt_ani_t2l0 {width:  780px; -webkit-animation: 10s moveT2L0 infinite linear;}
.tt_ani_t1l2 {width:  760px; -webkit-animation: 10s moveT1L2 infinite linear;}
.tt_ani_t1l1 {width:  620px; -webkit-animation:  9s moveT1L1 infinite linear;}
.tt_ani_t1l0 {width:  460px; -webkit-animation:  8s moveT1L0 infinite linear;}
.tt_ani_t0l2 {width:  360px; -webkit-animation:  8s moveT0L2 infinite linear;}
.tt_ani_t0l1 {width:  180px; -webkit-animation:  7s moveT0L1 infinite linear;}

.tt_txt {
	display: inline;
	height: 46px;
	line-height: 46px !important;
	text-align: center;
	font-size: 1.2rem;
	margin: 0px 4px 0px 4px;
	padding: 3px 6px 3px 3px;
}
.tt_wrap:hover .tt_cont {
	-webkit-animation-play-state: paused;
}

@-webkit-keyframes moveT2L2 {
	  0% {left:   360px;}
	100% {left: -1120px;}
}
@-webkit-keyframes moveT2L1 {
	  0% {left:   360px;}
	100% {left:  -940px;}
}
@-webkit-keyframes moveT2L0 {
	  0% {left:   360px;}
	100% {left:  -780px;}
}
@-webkit-keyframes moveT1L2 {
	  0% {left:   360px;}
	100% {left:  -760px;}
}
@-webkit-keyframes moveT1L1 {
	  0% {left:   360px;}
	100% {left:  -620px;}
}
@-webkit-keyframes moveT1L0 {
	  0% {left:   360px;}
	100% {left:  -460px;}
}
@-webkit-keyframes moveT0L2 {
	  0% {left:   360px;}
	100% {left:  -360px;}
}
@-webkit-keyframes moveT0L1 {
	  0% {left:   360px;}
	100% {left:  -180px;}
}

/* --------------------------- FOR KASSATSU ------------------------------ */
.kst_bdr_t {
	border-top: 1px solid #999999;
}
.kst_bdr {
	border: 1px solid #999999;
}